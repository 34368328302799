/* jshint esversion:6 */

var contact = (function() {

    // cache DOM
    var $msgSubmit = $('.msgSubmit');
    var $formSubmit = $('.form__submit');

    // will run if create product form was submitted
    $(document).on('submit', '#contactForm', function(evt) {

        // get form data
        var formData = JSON.stringify($(this).serializeObject());

        if (formData != null) {
            // submit form data to api
            $.ajax({
                url: "/api/objects/create",
                type: "POST",
                contentType: 'application/json',
                data: formData,
                success: function(result) {
                    // product was created, go back to products list
                    // console.log("Got it.");
                    $msgSubmit.text("Message Sent!");
                    $msgSubmit.addClass('h3');
                    $msgSubmit.removeClass('hidden');

                    $formSubmit.addClass('disabled');
                    $formSubmit.attr('disabled', 'disabled');
                },
                error: function(xhr, resp, text) {
                    // show error to console
                    console.error(xhr, resp, text);
                }
            });
        } else {
            console.error('form data is empty');
        }

        return false;
    });
})();

module.exports = contact;
