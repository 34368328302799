/* jshint esversion:6 */

$(document).ready(function () {

	var project = require("./components/project.js");
	var contact = require("./components/contact.js");

});

M.AutoInit();

var sidenavElem = document.querySelector('.sidenav');
var sidenavInstance = M.Sidenav.init(sidenavElem, {});

var elem = document.querySelector('.scrollspy');
var instance = M.ScrollSpy.init(elem, {});

// function to make form values to json format
// $.fn = $.prototype
$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
}; 