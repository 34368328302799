/* jshint esversion:6 */

var project = (function() {

    // cacheDOM
    var $projectLink = $(".project__link");
    var $backButton = $('#back-button');
    var $backBtn = $('.back-btn');
    // bind events
    $projectLink.click(showProject);
    $backButton.click(returnToGallery);

    // event methods
    function showProject(event) {
        event.preventDefault();

        // cacheDOM
        var $href = $(this).attr('href') + ' ' + $(this).attr('data-action'),
            $dataShow = $('#project-gallery-view'),
            $dataHide = $('#project-item'),
            $preLoader = $('#loader');
        //$backBtn       = $('.back-btn');

        $dataHide.animate({ 'marginLeft': '-120%' }, { duration: 400, queue: false });

        $dataHide.fadeOut(400);

        setTimeout(function() { $preLoader.show(); }, 400);
        setTimeout(function() {
            $dataShow.load($href, function() {
                $preLoader.hide();
                $dataShow.fadeIn(600);
                $backBtn.show().fadeIn(600);
            });
        }, 800);
    }

    function returnToGallery(event) {
        event.preventDefault();

        // cacheDOM
        var $dataShow = $('#project-item'),
            $dataHide = $('#project-gallery-view'),
            $dataAnimate = $("[data-animate]");

        $dataAnimate.each(function() {
            $(this).addClass($(this).attr('data-animate'));
        });

        $dataHide.fadeOut(400);
        //$(this).fadeOut(400);
        setTimeout(function() {
            $dataShow.animate({ 'marginLeft': '0' }, { duration: 400, queue: false });
            $dataShow.fadeIn(400);
            $backBtn.hide();


        }, 400);
        setTimeout(function() {
            $dataShow.find('.fadeInRight, .fadeInLeft, .fadeInUp, .fadeInDown').removeClass('fadeInRight').removeClass('fadeInLeft').removeClass('fadeInUp').removeClass('fadeInDown');
        }, 1500);
    }

    return {};
})();

module.exports = project;